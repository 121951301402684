<template>
  <v-modal
    ref="modal"
    target="root-modal"
    :backfrop-styles="$options.backdropStyles"
    close-on-backdrop-click
  >
    <div class="app-modal">
      <slot></slot>
    </div>
  </v-modal>
</template>

<script>
import { VModal } from '@arfedulov/vue2-modal';

export default {
  name: 'AppModal',
  components: { VModal },
  backdropStyles: {
    background: '#000000',
    opacity: '0.2'
  },
  methods: {
    open() {
      return this.$refs.modal.open();
    }
  }
};
</script>

<style scoped>
.app-modal {
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translate(-50%, 0);
  max-width: 100%;
}
</style>
