<template>
  <div class="start-page">
    <div class="banner start-page__banner">
      <div class="banner__dimmer"></div>
      <div class="banner-content banner__content">
        <img class="banner-content__logo" :src="$options.logoWithText" alt="skinxs" />
        <div
          class="banner-content__title u-typography-gilroy u-text-display  u-text-display--s u-text-display--bold"
        >
          Skin eXpert Software
        </div>
        <div class="banner-content__subtitle u-typography-gilroy u-text u-text--s">
          {{ $t('dashboard.landing.personalizedWorkspace') }}
        </div>
      </div>
      <div class="banner__copyright u-typography-gilroy u-text u-text--xs">© SkinXs 2022</div>
    </div>
    <div class="details start-page__details" :class="detailsClasses">
      <div class="nav-bar details__nav-bar" :class="navbarClasses">
        <a
          class="nav-bar__contact u-typography-gilroy u-text u-text--s u-text--link"
          tabindex="0"
          @click="openContactUsModal"
          @keypress.enter="openContactUsModal"
        >
          {{ $t('dashboard.landing.contactUs') }}</a
        >
        <img class="nav-bar__logo details__logo" :src="navbarLogo" alt="skinxs" />
        <u-dropdown
          class="nav-bar__language-select"
          :value="language"
          icon="globe"
          :options="$options.languageOptions"
          @input="onLanguageChange"
        >
          <template #value>{{ language.toUpperCase() }}</template>
          <template #option="{ option }">
            {{ option.title }}
          </template>
        </u-dropdown>
      </div>

      <div class="details__content">
        <slot></slot>
      </div>

      <div class="footer details__footer u-typography-gilroy u-text u-text--xs">
        <span class="footer__element footer__privacy">
          <span class="footer__accent-text">{{
            $t('dashboard.universkinFooter.privacyNotice')
          }}</span>
          &nbsp;
          <a class="footer__link" href="/doctor-notice" target="_blank">{{
            $t('dashboard.universkinFooter.privacyNoticeDoctors')
          }}</a>
          /
          <a class="footer__link" href="/patient-notice" target="_blank">{{
            $t('dashboard.universkinFooter.privacyNoticePatients')
          }}</a>
        </span>
        <span class="footer__element footer__copyright">© SkinXs 2022</span>
        <span class="footer__element footer__conditions">
          <a class="footer__link" href="/legal" target="_blank">{{
            $t('dashboard.universkinFooter.legal')
          }}</a>
          <span class="mr5 ml5 footer__accent-text">{{ $t('dashboard.footer.and') }}</span>
          <a class="footer__link" href="/terms" target="_blank">{{
            $t('dashboard.universkinFooter.termsOfUse')
          }}</a>
        </span>
      </div>
    </div>
    <contact-us-modal ref="contactUsModal" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import { UDropdown } from 'universkin-shared';

import ContactUsModal from '@/views/Modals/ContactUsModal';

import logoWithText from '@/assets/images/start-page/skinxs-logo.svg';
import logoSimple from '@/assets/images/start-page/skinxs-logo-simple.svg';

import rootTypes from '@/store/types';
import { DASHBOARD_LANGUAGE } from '@/modules/dashboard/constants/languages';

const LANGUAGE_OPTIONS = Object.entries(DASHBOARD_LANGUAGE).reduce(
  (options, [code, title]) => [...options, { code, title }],
  []
);

export default {
  name: 'StartPageTemplate',
  components: { UDropdown, ContactUsModal },
  logoWithText,
  logoSimple,
  languageOptions: LANGUAGE_OPTIONS,
  props: {
    compact: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      language: rootTypes.getters.GET_LOCALE
    }),
    navbarLogo() {
      return this.compact ? logoSimple : logoWithText;
    },
    detailsClasses() {
      return {
        'details--compact': this.compact
      };
    },
    navbarClasses() {
      return {
        'nav-bar--compact': this.compact
      };
    }
  },
  methods: {
    ...mapActions({
      changeLanguage: rootTypes.actions.SET_LOCALE
    }),
    async openContactUsModal() {
      await this.$refs.contactUsModal.open();
    },
    onLanguageChange({ code }) {
      this.changeLanguage(code);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~universkin-shared/src/assets/styles/scss/colors';
@import '~universkin-shared/src/assets/styles/scss/sizes';

.start-page {
  display: flex;
  width: 100%;
  min-height: 100vh;
  color: $color-graphite;

  &__banner {
    display: none;
  }

  &__details {
    width: 100%;
  }
}

.details {
  padding: 30px 20px 20px 20px;
  display: flex;
  flex-direction: column;
  background-color: $color-white;

  &__nav-bar {
    height: 207px;
  }

  &--compact {
    .details__nav-bar {
      height: 112px;
    }
  }

  &__content {
    display: flex;
    justify-content: center;
  }

  &__footer {
    margin-top: auto;
    padding-top: 36px;
  }
}

.banner {
  position: relative;
  background-image: url(../../assets/images/start-page/start-page-bg.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  &__dimmer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.6);
    backdrop-filter: blur(20px) opacity(0.6);
  }

  &__background {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  &__content {
    z-index: 1;
  }

  &__copyright {
    color: $color-graphite;
    z-index: 1;
  }
}

.nav-bar {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  &__logo {
    max-height: 78px;
    align-self: center;
  }

  &__language-select {
    width: 98px;
    background-color: inherit;
    margin-top: -15px;
  }

  &__contact {
    color: inherit;
    line-height: 30px; /* used for link alignment in nav-bar */
  }

  &--compact {
    .nav-bar__logo {
      align-self: start;
      transform: translateY(-25%);
    }
  }
}

.footer {
  display: flex;
  flex-wrap: wrap;
  color: $color-grey400;

  &__element {
    margin-right: 17px;
  }

  &__privacy {
    width: 100%;
    margin-bottom: 16px;
  }

  &__conditions {
    margin-right: 0;
  }

  &__accent-text {
    color: $color-grey400;
  }

  &__link {
    color: inherit;
    text-decoration: underline;
  }
}

@media (min-width: 414px) {
  .details {
    padding: 56px 24px 24px 24px;

    &__nav-bar {
      height: 340px;
    }

    &--compact {
      .details__nav-bar {
        height: 164px;
      }
    }
  }

  .nav-bar {
    &__language-select {
      margin-top: -16px;
    }
  }

  .footer {
    &__element {
      margin-right: 25px;
    }

    &__privacy {
      width: 100%;
      margin-bottom: 24px;
    }

    &__conditions {
      margin-right: 0;
    }
  }
}

@media (min-width: $medium-start) {
  .nav-bar {
    &__language-select {
      width: 110px;
      margin-top: -14px;
    }

    &__contact {
      line-height: 32px;
      cursor: pointer;
    }
  }
}

@media (min-width: $desktop-start) {
  .start-page {
    &__banner {
      display: block;
      flex: 1 1 50%;
    }

    &__details {
      flex: 1 1 50%;
    }
  }

  .banner {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__content {
      margin-top: 50%;
      transform: translateY(-50%);
    }

    &__copyright {
      margin-top: auto;
      margin-bottom: 42px;
    }
  }

  .banner-content {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__logo {
      max-width: 164px;
    }

    &__title {
      margin-top: 60px;
      margin-bottom: 12px;
      color: $color-graphite;
    }

    &__subtitle {
      color: $color-grey600;
    }
  }

  .details {
    padding: 47px 90px 38px 70px;
  }

  .details {
    &__nav-bar {
      height: 158px;
    }

    &__content {
      justify-content: start;
    }
  }

  .nav-bar {
    &__contact {
      margin-left: auto;
      margin-right: 30px;
    }

    &__logo {
      display: none;
    }
  }

  .footer {
    color: $color-graphite;

    &__element {
      margin-right: 47px;
    }

    &__link {
      text-decoration: none;
    }

    &__privacy {
      width: auto;
    }

    &__copyright {
      display: none;
    }

    &__conditions {
      margin-right: 0;
    }
  }
}

@media (min-width: $large-start) {
  .nav-bar {
    &__language-select {
      width: 120px;
      margin-top: -19px;
    }

    &__contact {
      line-height: 38px;
    }
  }

  .details {
    padding: 47px 150px 38px 130px;
  }
}
</style>
