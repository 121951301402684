<template>
  <div class="success-message">
    <div class="success-message__image">
      <img src="~@/assets/images/start-page/envelope.svg" alt="envelope" />
    </div>
    <h2
      class="success-message__heading u-typography-gilroy u-text-display  u-text-display--m u-text-display--bold"
    >
      {{ $t('dashboard.landing.resetLinkWasSent') }}
    </h2>
    <p
      class="success-message__paragraph success-message__just-sent u-typography-gilroy u-text u-text--m"
    >
      {{ $t('dashboard.landing.weJustSent') }}
    </p>
    <p
      class="success-message__paragraph success-message__having-problems u-typography-gilroy u-text u-text--s"
    >
      {{ $t('dashboard.landing.havingProblems') }}
    </p>
    <p
      class="success-message__paragraph success-message__contact-us u-typography-gilroy u-text u-text--s"
    >
      {{ $t('dashboard.landing.contactSupportBy') }}
      <a class="link u-text--link" href="mailto:info@universkin.com">info@universkin.com</a>
    </p>
    <u-button class="success-message__button" kind="subtle" @click="onSubmit">{{
      $t('dashboard.landing.backToSignIn')
    }}</u-button>
  </div>
</template>

<script>
import { UButton } from 'universkin-shared';

export default {
  name: 'ResetPasswordLinkSentMessage',
  components: { UButton },
  methods: {
    onSubmit() {
      this.$emit('submit');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~universkin-shared/src/assets/styles/scss/colors';
@import '~universkin-shared/src/assets/styles/scss/sizes';

.link {
  color: $color-graphite;
}

.success-message {
  max-width: 350px;

  &__image {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  &__heading {
    margin: 38px 0 8px 0;
    color: $color-graphite;
  }

  &__paragraph {
    color: $color-grey600;
    padding: 0;
    margin: 0 0 24px 0;
  }

  &__just-sent {
    color: $color-grey900;
  }

  &__having-problems,
  &__contact-us {
    margin: 0;
  }

  &__button {
    margin-top: 32px;
  }
}

@media (min-width: $desktop-start) {
  .success-message {
    &__image {
      justify-content: start;
    }

    &__just-sent {
      color: $color-grey600;
    }
  }
}
</style>
