<template>
  <start-page-template compact>
    <reset-password-link-sent-message v-if="isEmailSuccessfullySent" @submit="goToSignInPage" />
    <forgot-password-form
      v-else
      :loading="isLoading"
      :error="error"
      @submit-email="onSendResetLink"
      @back="onBack"
    />
  </start-page-template>
</template>

<script>
import StartPageTemplate from '@/components/templates/StartPageTemplate';
import ForgotPasswordForm from '@/components/start-page/ForgotPasswordForm';
import ResetPasswordLinkSentMessage from '@/components/start-page/ResetPasswordLinkSentMessage';

import { sendPasswordReset } from '@/api';
import { isErrorResponse } from '@/api/selectors';

const REQUESTED_RESOURCE_ERROR = 'REQUESTED_RESOURCE_ERROR';

export default {
  name: 'ForgotPasswordPage',
  components: { StartPageTemplate, ForgotPasswordForm, ResetPasswordLinkSentMessage },
  data() {
    return {
      isLoading: false,
      error: '',
      isEmailSuccessfullySent: false
    };
  },
  methods: {
    async onSendResetLink(email) {
      this.clearError();

      this.setLoading(true);

      const response = await sendPasswordReset({ email, isPatient: false });

      this.setLoading(false);

      const { data = {} } = response;
      const { message } = data;

      if (isErrorResponse(response)) {
        this.error = 'Sending attempt failed';

        return;
      }

      if (message === REQUESTED_RESOURCE_ERROR) {
        const errorMessage = this.$t('dashboard.landing.emailAddressIsNotLinked');
        this.error = `${errorMessage} info@universkin.com`;
      }

      if (!this.error) {
        this.isEmailSuccessfullySent = true;
      }
    },
    onBack() {
      this.$router.go(-1);
    },
    goToSignInPage() {
      this.$router.push({ name: 'Home' });
    },
    setLoading(value) {
      this.isLoading = value;
    },
    clearError() {
      this.error = '';
    }
  }
};
</script>
