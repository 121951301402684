<template>
  <div class="reset-password-form">
    <h2
      class="reset-password-form__heading u-typography-gilroy u-text-display  u-text-display--m u-text-display--bold"
    >
      {{ $t('dashboard.landing.resetPassword') }}
    </h2>
    <p class="reset-password-form__info u-typography-gilroy u-text u-text--m">
      {{ $t('dashboard.landing.fillYourEmail') }}
    </p>
    <u-form ref="resetPasswordForm" class="form" @submit.prevent="onSubmit">
      <u-input
        v-model="email"
        type="text"
        :label="$t('dashboard.landing.input.email')"
        :validators="[validateEmail]"
      />
      <u-button class="form__primary-button" type="submit" :loading="loading">
        {{ $t('dashboard.landing.button.requestResetLink') }}
      </u-button>

      <u-button kind="text" type="button" @click="onBack">{{
        $t('dashboard.action.back')
      }}</u-button>

      <div v-if="error" class="form__error-message u-typography-gilroy u-text u-text--xs">
        <u-icon name="alert-triangle" />
        {{ error }}
      </div>

      <div
        class="support-message reset-password-form__support-message u-typography-gilroy u-text u-text--m"
      >
        <div class="support-message__paragraph">
          {{ $t('dashboard.landing.havingProblems') }}
        </div>
        <div class="support-message__paragraph">
          {{ $t('dashboard.landing.contactSupportBy') }}
          <a
            class="support-message__link u-typography-gilroy u-text u-text--m u-text--link"
            href="mailto:info@universkin.com"
            >info@universkin.com</a
          >
        </div>
      </div>
    </u-form>
  </div>
</template>

<script>
import { UButton, UForm, UInput, UIcon } from 'universkin-shared';

export default {
  name: 'ForgotPasswordForm',
  components: { UButton, UForm, UInput, UIcon },
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    error: {
      type: String,
      default: ''
    }
  },
  data() {
    return { email: '' };
  },
  methods: {
    validateEmail(value) {
      return !!value || this.$t('dashboard.authentication.filedRequired');
    },
    onSubmit() {
      this.$emit('submit-email', this.email);
    },
    onBack() {
      this.$emit('back');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~universkin-shared/src/assets/styles/scss/colors';
@import '~universkin-shared/src/assets/styles/scss/sizes';

.reset-password-form {
  &__heading {
    margin: 0 0 13px 0;
  }

  &__info {
    color: $color-grey600;
    margin: 0 0 37px 0;
  }

  &__support-message {
    margin-top: 20px;
  }
}

.form {
  display: flex;
  flex-direction: column;

  &__password {
    margin-top: 24px;
  }

  &__primary-button {
    margin-top: 32px;
    margin-bottom: 8px;
  }

  &__error-message {
    color: $color-error;
    --u-icon-color: #{$color-error};
    margin-top: 5px;
    text-align: center;
  }
}

.support-message {
  color: $color-grey600;

  &__paragraph {
    margin-bottom: 8px;
  }

  &__link {
    color: $color-graphite;
  }
}

@media (min-width: 500px) and (max-width: 599px) {
  .reset-password-form {
    padding: 0 30px;
  }
}

@media (min-width: $medium-start) {
  .reset-password-form {
    max-width: 430px;
  }
}

@media (min-width: $desktop-start) {
  .reset-password-form {
    max-width: 325px;

    &__info {
      margin-bottom: 18px;
    }
  }

  .form {
    &__password {
      margin-top: 22px;
    }
  }
}
</style>
