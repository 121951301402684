<template>
  <app-modal ref="modal">
    <div class="contact-us-modal">
      <p class="title text-center">
        {{ $t('dashboard.landing.contactUs') }}
      </p>
      <div class="text-center">
        <div>
          {{ $t('dashboard.landing.generalInquiries') }}
          <div class="mail-text">
            info@universkin.com
          </div>
        </div>
        <br />
        <div>
          {{ $t('dashboard.landing.pressAndMedia') }}
          <div class="mail-text">
            press@universkin.com
          </div>
        </div>
        <br />
        <div>
          {{ $t('dashboard.landing.forMail') }}
          <div>12 Rue Paul Déroulède,</div>
          <div>06 000 Nice - FRANCE</div>
        </div>
      </div>
      <button v-modal-submit class="my-btn rounded-button-white close-button">
        {{ $t('dashboard.landing.button.close') }}
      </button>
    </div>
  </app-modal>
</template>

<script>
import AppModal from '@/components/common/AppModal';

export default {
  name: 'ContactUsModal',
  components: { AppModal },
  methods: {
    open() {
      return this.$refs.modal.open();
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/common/buttons.scss';
@import '~@/assets/css/common/text.css';

.contact-us-modal {
  width: 500px;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: var(--u-font-family-Gilroy);
  background-color: var(--u-color-white);
  border-radius: 10px;
  font-size: 14px;
  font-weight: normal;
  line-height: 21px;
  padding: 32px 16px;
}

.title {
  margin-bottom: 32px;
}

.close-button {
  margin-top: 32px;
}
</style>
